import './App.css';





function App() {
  return (
    <div className="App">
      <video className='videoTag' autoPlay loop muted>
          <source src={'https://om3n.s3.eu-north-1.amazonaws.com/fffbg.mp4'} type='video/mp4' />
      </video>
      <p className="hero" >
      <h1>"the obsession I begin with now."</h1>
      </p>
      <img src={'https://om3n.s3.eu-north-1.amazonaws.com/logo.png'} className="logo" />

      <p className="gift" >
      <h1>"We are"</h1>
      <p> "not good nor evil, we create for the sake of lovely complexity. Every act you may have seen as paranormal or just coincidentally beautiful earlier in your life has been performed by Om3ns. We are the deepness of the sea, the eeriness of the woods. We dwell in the void. You may wonder if this void is located on earth, well its complicated. But soon you may see."</p>
      
      <h1>"The finest"</h1>
      <p>"collection of fine art pfps on chain. om3ns are beings not held down by the standards of others. chaotic, provoking, inspiring."</p>
      <div class="row">
      <img src={'https://om3n.s3.eu-north-1.amazonaws.com/1.png'} className="art" />
      <img src={'https://om3n.s3.eu-north-1.amazonaws.com/2.png'} className="art" />
      <img src={'https://om3n.s3.eu-north-1.amazonaws.com/3.png'} className="art" />
      </div>
      
      <h1>"soon"</h1>
      <p>"Om3ns will become acquirable for chosen individuals on the Solana blockchain. to be a chosen one = get in the void. supply and price tba."</p>
      
      <h1>"the blueprint"</h1>
      <p>1."The cockrow" <br></br>
       - get in the void.<br></br>
       - rage and chill.<br></br>
       - mint.
      </p>
      
      <p>2."The void dao" <br></br>

       - up the infra.<br></br>
       - elect the council.<br></br>
       - get paid.
      </p>

      <p>3."The shining" <br></br>
      - new fine art.<br></br>
      - Shiny bright objects.<br></br>
      - maybe a castle.
      </p>


      <h1>"The void dao"</h1>
      <p>"has two main objectives: encourage originality and artistic qualities and benefit its members financially. the chosen council manages the means to achieve this."</p>
     
      <img src={'https://om3n.s3.eu-north-1.amazonaws.com/remus.png'} className="remus" />
      <h1>"remus"</h1>
      <p>"father of om3ns. artist and established builder-innovator in the solana space, remus makes all kinds of stuff himself and knows people who get things done."</p>
      <h1>"your chance is:"</h1>
      <p>"get in the void discord by interacting with our
         <a onClick={() => {window.location.href="https://twitter.com/VOM3NOID"}}> twitter </a>
          account. we appreciate all creatives. we might also grant access to other solana communities:
           apply <a onClick={() => {window.location.href="https://forms.gle/YEDZavqpcZrMR8gw9 "}}>here</a>"
      </p>
      
      
      <br></br>
      <br></br>
      <a onClick={() => {window.location.href="https://twitter.com/VOM3NOID"}}>"bird"</a>
      </p>
      
      
    </div >
  );
}

// git commit -a -m  "asa"
// git push -u -f origin main


export default App;
